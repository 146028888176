.navContainer {
  list-style-type: none;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin: 0;
  padding: 12px 24px;
  font-size: 1.4em;

  a {
    text-decoration: none;
    color: white;
    padding: 4px 8px;
  }

  .currentItem {
    text-decoration: underline;
    font-weight: bold;
  }
  .item:hover {
    background-color: #C9E3CC;
    color: black;
  }
  .galeryDropdown {
    display: none;
    position: absolute;
    background-color: black;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 8px;
    z-index: 1;
  }
  li[name="galerie"]:hover .galeryDropdown{
      display: block;
      a {
        display: block;
        &:hover {
          background-color: #C9E3CC;
          color: black;
        }
      }
    }

  .logBtn {
    cursor: pointer;
    border-radius: 8px;
    padding: 4px 8px;
    position: absolute;
    right: 24px;
    font-size: 0.8em;
  }
  .logBtn:hover {
    color: white;
    background-color: black;
    border: 1px solid white;
  }
}
