.filtersContainer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 12px;
  margin: 8px auto 0;
  padding: 8px 16px;
  border: 1px dashed #b0b9a8;
  border-radius: 48px;
  width: fit-content;
  max-width: calc(100vw - 48px);
  .checkAndLabel {
    display: flex;
    align-items: flex-end;
  }
}
.galleryContainer {
  background-color: #b0b9a8;
  border-radius: 40px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 240px);
  justify-content: space-evenly;
  column-gap: 16px;
  row-gap: 24px;
  padding: 16px 16px 20px;
  margin: 8px 4vw 6vh;
}
.productWrapper {
  background-color: #c9e3cc;
  box-shadow: 8px 10px 8px 0px rgba(0, 0, 0, 0.75);
  border-radius: 8px;
  padding: 8px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

@media (max-width: 870px) {
  .galleryContainer {
    grid-template-columns: repeat(auto-fill, 200px);
  }
}
@media (max-width: 740px) {
  .galleryContainer {
    grid-template-columns: repeat(auto-fill, 180px);
  }
}
@media (max-width: 675px) {
  .galleryContainer {
    grid-template-columns: repeat(auto-fill, 160px);
    grid-gap: 12px;
    padding: 12px 12px 16px;
  }
}
@media (max-width: 592px) {
  .galleryContainer {
    grid-template-columns: repeat(auto-fill, 150px);
    grid-gap: 12px;
    padding: 12px 8px 16px;
    border-radius: 30px;
  }
}
@media (max-width: 360px) {
  .galleryContainer {
    grid-template-columns: repeat(auto-fill, 130px);
    column-gap: 8px;
    row-gap: 12px;
    padding: 12px 8px;
    border-radius: 20px;
  }
}
@media (max-width: 308px) {
  .galleryContainer {
    grid-template-columns: repeat(auto-fill, 116px);
    column-gap: 8px;
    row-gap: 12px;
    padding: 8px 8px 12px;
    border-radius: 20px;
  }
}
