.icon {
  width: 32px;
  height: 32px;
  cursor: pointer;
}
.closeIcon {
  position: absolute;
  right: 24px;
}
.productWrapper {
  margin: 48px 12vw;
  display: flex;
  column-gap: 48px;

  .bloc1 {
    // .hideIcons {
    //   z-index: 0;
    // }
    width: 50%;
    .thumbnailItem {
      height: 70px;
      width: 50px;
    }
  }
  .bloc2 {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;

    p {
      text-align: justify;
      white-space: pre-wrap;
    }
  }
}

@media (max-width: 600px) {
  .productWrapper {
    flex-direction: column;
    margin: 12px 0;
    .bloc1,
    .bloc2 {
      width: 100%;
    }
  }
}
