.productWrapper {
  margin: 48px 12vw;
  display: grid;
  grid-template-columns: 50% 50%;
  column-gap: 48px;

  .bloc1 {
    .hideIcons {
      z-index: 0
    }
    .thumbnailItem {
      height: 70px;
      width: 50px;
    }
  }
  .bloc2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;

    p {
      text-align: justify;
    }
  }
}
