.loginForm {
  display: flex;
  flex-direction: column;
  align-items: center;

  label {
    margin-bottom: 8px;
  }
  input {
    width: 200px;
    margin-bottom: 16px;
    font-size: 1em;
    border-radius: 8px;
    padding: 4px 8px;
  }
}
