.mainContainer {
  margin: 0 24px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  div,
  p {
    max-width: 1000px;
  }
  p {
    text-align: justify;
  }
  a {
    all: unset;
    cursor: pointer;
    margin: 24px 0;
  }

  .categoryWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    padding: 8px 16px;
    border: 1px dashed #b0b9a8;
    border-radius: 48px;
    h3 {
      text-align: left;
      margin: 0;
    }
    p {
      max-width: 700px;
    }
    img {
      box-shadow: 2px 2px 4px 0px rgb(0 0 0 / 50%);
      border-radius: 50%;
    }
  }
}

@media (max-width: 600px) {
  .categoryWrapper {
    flex-direction: column-reverse;
  }
}
