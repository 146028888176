.App {
  text-align: center;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@import url("https://fonts.googleapis.com/css2?family=Amita:wght@700&family=Satisfy&display=swap");

h1 {
  font-family: "Amita", cursive;
  margin: 0;
}
h2 {
  font-family: "Satisfy", cursive;
  margin: 0;
}

button {
  border-radius: 8px;
  padding: 4px 8px;
  cursor: pointer;
}
.btn-black {
  background-color: black;
  color: white;
  font-size: 0.9em;
  min-width: 60px;
}
.btn-black:hover {
  background-color: white;
  color: black;
}

.buttons-wrapper {
  margin-top: 12px;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}
