.imageListContainer {
    display: flex;
    min-height: 100px;
}
.previewContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  p {
    margin: 0;
    font-size: 0.8em;
    cursor: pointer;
  }
}
